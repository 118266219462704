import { fvGsapMove } from '../_component/_gsap';
import { gsapMove } from '../_component/_gsap';
import { initSwiper } from "../_component/_swiper"

window.addEventListener('DOMContentLoaded', function() {
  document.getElementById('loader').classList.add('loaded');
  document.querySelector('body').style.opacity = "0";

  window.addEventListener('load', function() {
    fvGsapMove(); // Initial animations for the 'section-fv' elements
    gsapMove();   // Scroll-triggered animations
    initSwiper(); // Initialize Swiper after everything is loaded
  });
});