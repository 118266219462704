import Swiper from 'swiper/bundle';
import 'swiper/css';
// SwiperのJavaScript
import pagination from 'swiper/css/pagination';
import navigation from 'swiper/css/navigation';

export function initSwiper() {
  const mySwiper = new Swiper('.swiper', {
    loop: true,
    grabCursor: true,
    effect: "coverflow",
    slidesPerView: "auto",
    centeredSlides: true,
    breakpoints: {
      0: {
        coverflowEffect: {
          rotate: 50,             // 追加 (前後のスライドの回転)
          depth: 100,             // 追加 (前後のスライドの奥行)
          stretch: 120,           // 追加 (スライド間のスペース)
          modifier: 1,            // 追加 (rotate・depth・stretchの値を乗算する)
          scale: 0.7,             // 追加 (前後のスライドのサイズ比率)
          slideShadows: false,    // 追加 (前後のスライド表面の影の有無)
        },
      },
      750: {
        coverflowEffect: {
          rotate: 45,             // 追加 (前後のスライドの回転)
          depth: 200,             // 追加 (前後のスライドの奥行)
          stretch: 330,           // 追加 (スライド間のスペース)
          modifier: 1,            // 追加 (rotate・depth・stretchの値を乗算する)
          scale: 0.7,             // 追加 (前後のスライドのサイズ比率)
          slideShadows: false,    // 追加 (前後のスライド表面の影の有無)
        },
      },
      850: {
        coverflowEffect: {
          rotate: 45,             // 追加 (前後のスライドの回転)
          depth: 200,             // 追加 (前後のスライドの奥行)
          stretch: 310,           // 追加 (スライド間のスペース)
          modifier: 1,            // 追加 (rotate・depth・stretchの値を乗算する)
          scale: 0.7,             // 追加 (前後のスライドのサイズ比率)
          slideShadows: false,    // 追加 (前後のスライド表面の影の有無)
        },
      },
      1200: {
        coverflowEffect: {
          rotate: 45,             // 追加 (前後のスライドの回転)
          depth: 130,             // 追加 (前後のスライドの奥行)
          stretch: 450,           // 追加 (スライド間のスペース)
          modifier: 1,            // 追加 (rotate・depth・stretchの値を乗算する)
          scale: 0.7,             // 追加 (前後のスライドのサイズ比率)
          slideShadows: false,    // 追加 (前後のスライド表面の影の有無)
        },
      },
      1500: {
        coverflowEffect: {
          rotate: 45,             // 追加 (前後のスライドの回転)
          depth: 120,             // 追加 (前後のスライドの奥行)
          stretch: 400,           // 追加 (スライド間のスペース)
          modifier: 1,            // 追加 (rotate・depth・stretchの値を乗算する)
          scale: 0.7,             // 追加 (前後のスライドのサイズ比率)
          slideShadows: false,    // 追加 (前後のスライド表面の影の有無)
        },
      },
    },
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  window.mySwiper = mySwiper; // Global reference if needed
}
