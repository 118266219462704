//
// メニュー
// ------------------------------
$(function () {
  $(".menu-hum__trigger").on("click", function () {
    $(this).toggleClass("active");
    return false;
  });
  $(".menu a").on("click", function () {
    $(".menu").toggleClass("active");
    $(".menu-hum__trigger").toggleClass("active");
  });
  var $wrap = $(".menu");
  $(".menu-hum__trigger").on("click", function () {
    $wrap.toggleClass("open");
  });
  $("#menu-hum__trigger").on("click", function () {
    $wrap.removeClass("open");
  });
  var $wrap = $(".menu");
  $(".menu a").on("click", function () {
    $wrap.removeClass("open");
  });
});
