import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export function fvGsapMove() {
  const gsapObjs = {};
  const target = document.querySelector('.section-fv');

  gsapObjs.obj01 = '#section-fv__img--01';
  gsapObjs.obj02 = '#section-fv__img--02';
  gsapObjs.obj03 = '#section-fv__img--03';
  gsapObjs.obj04 = '.section-fv__txt';
  gsapObjs.obj05 = ':root'; // 疑似要素による背景の操作
  gsapObjs.obj06 = '.section-fv-lower'; // swiper部分の操作
  gsap.set(gsapObjs.obj05, {'--bg-opacity': '0'});

  // fvアニメーション
  const tl = gsap.timeline();
  tl.to('body', { // body
    opacity: 1.0,
  }).fromTo(gsapObjs.obj01, {
    opacity: 0,
    transformOrigin: 'top right',
  }, {
    opacity: 1.0,
    duration: 1.5,
    ease: 'power2.out'
  }, '>0.5').fromTo(gsapObjs.obj02, {
    opacity: 0,
    transformOrigin: 'top right'
  }, {
    opacity: 1.0,
    duration: 1.5,
    ease: 'power2.out'
  }, '-=1.0').fromTo(gsapObjs.obj03, {
    opacity: 0,
    transformOrigin: 'top right',
  }, {
    opacity: 1.0,
    duration: 1.5,
    ease: 'power2.out'
  }, '-=1.0').fromTo(gsapObjs.obj04, {
    opacity: 0,
  }, {
    opacity: 1.0,
    duration: 1.0,
    ease: 'power2.out'
  }, '>').to(gsapObjs.obj05, {
    '--bg-opacity': '1',
    duration: 1.0,
    delay: 0.5,
    ease: 'power2.out'
  }, '<').fromTo(gsapObjs.obj06, {
    opacity: 0,
  }, {
    opacity: 1.0,
    duration: 1,
    ease: 'power2.inOut'
  }, '<').add(() => {
    target.classList.add('hover-active');
  });
}

/*
    animationさせる要素に以下のどれかを付加する。
    '.fade-up-el__t' 下から上にフェードイン
    '.fade-left-el__t' 右から左にフェードイン
    '.fade-right-el__t' 左から右にフェードイン
    '.js-animation-to-right-d-el' 左から右にフェードイン(遅め)
*/
export function gsapMove(){
  const itemsToUp = gsap.utils.toArray('.fade-up-el__t'); // 下から上にフェードイン
  itemsToUp.forEach((itemsToUp) => {
    gsap.fromTo(itemsToUp,
      {
        y: 50,
        autoAlpha: 0
      },
      {
        y: 0,
        autoAlpha: 1,
        delay: 0.3,
        duration: 1.2,
        ease: 'power2.out',
        scrollTrigger: {
          toggleActions: 'play pause resume reset',
          trigger: itemsToUp,
          start: 'top bottom-=100'
        }
      }
    );
  });

  const itemsToLeft = gsap.utils.toArray('.fade-left-el__t'); // 右から左にフェードイン
  itemsToLeft.forEach((itemsToLeft) => {
    gsap.fromTo(itemsToLeft,
      {
        x: 50,
        autoAlpha: 0
      },
      {
        x: 0,
        autoAlpha: 1,
        delay: 0.3,
        duration: 1.2,
        ease: 'power2.out',
        scrollTrigger: {
          toggleActions: 'play pause resume reset',
          trigger: itemsToLeft,
          start: 'top bottom-=100'
        }
      }
    );
  });

  const itemsToRight = gsap.utils.toArray('.fade-right-el__t'); // 左から右にフェードイン
  itemsToRight.forEach((itemsToRight) => {
    gsap.fromTo(itemsToRight,
      {
        x: -50,
        autoAlpha: 0
      },
      {
        x: 0,
        autoAlpha: 1,
        delay: 0.3,
        duration: 1.2,
        ease: 'power2.out',
        scrollTrigger: {
          toggleActions: 'play pause resume reset',
          trigger: itemsToRight,
          start: 'top bottom-=100'
        }
      }
    );
  });
}
